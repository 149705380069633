<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.financialDues')"
        :pgIcon="'bx bx-money'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <CardsAmount :refs="refs" :slug="slug"> </CardsAmount>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  components: {
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    CardsAmount: () => import("@/components/CardsAmount.vue"),
  },
  data() {
    return {
      //
      addNew: false,
      cards: [],
      refs: "financialdues",
      slug: "financialdues",
    };
  },
  watch: {
    //
  },
  mounted() {},
  created() {
    //
  },
  methods: {
    handlePermissoinChange(event) {
      console.log(event);
      //this.addNew = event;
    },
  },
};
</script>
